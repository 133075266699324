<template>
  <div class="amap-page-container">
    <el-amap
      ref="map"
      vid="amapDemo"
      :amap-manager="amapManager"
      :center="center"
      :zoom="zoom"
      :plugin="plugin"
      class="amap-demo"
    >
      <el-amap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :vid="index"
      ></el-amap-marker>
      <el-amap-text
        v-for="(text,index) in texts"
        :key="index"
        :text="text.text"
        :offset="text.offset"
        :position="text.position"
        class="fontSmall"
      ></el-amap-text>
    </el-amap>
    <van-button class="bottonArea" type="primary" @click="goBack">返回上一页</van-button>
  </div>
</template>

<style scoped>
.amap-page-container {
  height: 100%;
  position: relative;
}
.amap-demo {
  height: 100%;
}
.bottonArea {
  position: absolute;
  bottom: 1rem;
  left: 0.8rem;
  border-radius: 0.16rem;
}
</style>
<style>
.amap-icon img {
  width: 30px;
  height: 30px;
}
.amap-overlay-text-container {
  font-size: 0.4rem;
}
</style>
<script>
// NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
  key: '09ba3397bed53916f7419b24ae4a9707',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  uiVersion: '1.0.11', // 版本号
  v: '1.4.4'
});
import { AMapManager } from 'vue-amap';
// CDN 方式
let amapManager = new VueAMap.AMapManager();
export default {
  data: function() {
    const this_ = this;
    const position = this_.$route.query.position;
    const title = this_.$route.query.text;
    console.log(this_.$route.query);
    return {
      amapManager,
      zoom: 12,
      center: position,
      plugin: [
        'ToolBar',
        {
          pName: 'MapType',
          defaultType: 0,
          events: {
            init(o) {
              console.log(o);
            }
          }
        }
      ],
      markers: [
        {
          position: position
        }
      ],
      texts: [
        {
          position: position,
          text: title,
          offset: [0, -50]
        }
      ]
    };
  },

  //       created() {
  //         let self = this;
  //         let markers = [];
  //         let index = 0;

  //         let basePosition = [121.59996, 31.197646];
  //         let num = 10;

  //         for (let i = 0 ; i < num ; i++) {
  //           markers.push({
  //             position: [basePosition[0], basePosition[1] + i * 0.03],
  //             icon:require('../../assets/img/wc.png')
  //           });
  //         }
  //         this.markers = markers;
  //       }
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
